


















































































import {
  cardNameHandler,
  cardTypeHandler,
  cardSurfaceNumberHandler,
} from "@/utils/ffxiv-text-tool";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Card } from "@/interface/FfxivDTO";

@Component({
  components: {},
})
export default class CardInfoBox extends Vue {
  @Prop() private cardBoxData!: Card;
  @Prop() private color?: string;
  @Prop() private width?: number;

  cardNameHandler = cardNameHandler;
  cardSurfaceNumberHandler = cardSurfaceNumberHandler;
  cardTypeHandler = cardTypeHandler;
}
