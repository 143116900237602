import Vue from "vue";
import Component from "vue-class-component";
import FfxivCardData, { cardTypeHandler, cardSurfaceNumberHandler, cardNameHandler } from "@/utils/ffxiv-text-tool";
import { Card } from "@/interface/FfxivDTO";
import CardInfoBox from "@/components/ffxiv/CardInfoBox.vue"
import { authCheck } from '@/utils/ffxiv-text-tool';

@Component({
  components: {
    CardInfoBox
  },
})
export default class FFXIVNineCardEdit extends Vue {

  selectCardPictureFiles: any = [];
  selectCardIconPictureFiles: any = [];

  cardInfoDialog = false;
  cardsRequestLoading = true;

  cardRarityItems = FfxivCardData.cardRarityItems;

  cardTypeItems = FfxivCardData.cardTypeItems;

  cardNameHandler = cardNameHandler;

  cardSurfaceNumberHandler = cardSurfaceNumberHandler;

  cardTypeHandler = cardTypeHandler;

  authCheck = authCheck;

  

  cardForm = {
    card: {
      id: -1,
      rarity: 1,
      top: 1,
      bottom: 1,
      left: 1,
      right: 1,
      type: 0,
      cardNo: '',
      cardCode: '',
      name: '',
      describe: '',
      title: '',
    } as Card,
    pictureBase64: '',
    iconBase64: '',
    picture: '/ffxiv/surface/null-surface.png',
    icon: '',
    loading: false,
    valid: false,
    pictureLoading: false
  }

  cardList = [];

  validate() {
    const formData = {
      card: this.cardForm.card,
      pictureBase64: this.cardForm.pictureBase64,
      iconBase64: this.cardForm.iconBase64
    }
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/card/save',
      method: 'put',
      data: formData
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.getAllCard();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }


  selectCard(item: any) {
    if(!authCheck()){
      return ;
    }
    const pictureUrl = `/resources/ffxiv/surface/${item.cardCode}.png`;
    const iconUrl = `/resources/ffxiv/surface_icon/${item.cardCode}.png`;
    this.cardForm.picture = pictureUrl;
    this.cardForm.icon = iconUrl;
    this.cardForm.pictureBase64 = '';
    this.cardForm.iconBase64 = '';

    this.cardForm.card = item;

    this.cardInfoDialog = true;

  }

  mounted() {
    this.getAllCard();
  }


  getAllCard() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/card',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.cardsRequestLoading = false;
        _this.cardList = response.data.data;
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }


  changeCardPictureFile() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      _this.cardForm.picture = e.target.result;
      _this.cardForm.pictureBase64 = e.target.result.substring('data:image/png;base64,'.length);
    }
    reader.readAsDataURL(_this.selectCardPictureFiles);
    this.cardForm.pictureLoading = true;
    const timer = setInterval(function () {
      _this.cardForm.pictureLoading = false;
      clearInterval(timer);
    }, 1000);
  }

  changeCardIconPictureFile() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {

      _this.cardForm.icon = e.target.result;
      _this.cardForm.iconBase64 = e.target?.result.substring('data:image/png;base64,'.length);
    }
    reader.readAsDataURL(_this.selectCardIconPictureFiles);
    this.cardForm.pictureLoading = true;
    const timer = setInterval(function () {
      _this.cardForm.pictureLoading = false;
      clearInterval(timer);
    }, 1000);
  }

  newCard() {
    this.cardForm.card = {
      id: -1,
      rarity: 1,
      top: 1,
      bottom: 1,
      left: 1,
      right: 1,
      type: 0,
      cardNo: '',
      cardCode: '',
      name: '',
      describe: '',
      title: '',
    };
    this.cardForm.pictureBase64 = '';
    this.cardForm.iconBase64 = '';
    this.cardForm.picture = '/ffxiv/surface/null-surface.png';
    this.cardForm.icon = '';
  }

  deleteCard() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/ffxiv/card',
      method: 'delete',
      data: {
        id: _this.cardForm.card.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.getAllCard();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

}