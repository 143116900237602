<template>
  <div class="ffxiv-card-edit">
    <v-skeleton-loader
      v-if="cardsRequestLoading"
      dark
      type="article"
    ></v-skeleton-loader>

    <v-dialog v-model="cardInfoDialog" width="800">
      <v-card dark>
        <v-card-title>卡牌信息编辑</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="3">
              <v-row dense>
                <v-col cols="12">
                  <v-card width="150" class="ma-1 pa-2 text-center">
                    <v-badge
                      avatar
                      overlap
                      offset-x="25"
                      offset-y="25"
                      color="transparent"
                    >
                      <template v-slot:badge>
                        <v-avatar>
                          <v-img
                            v-if="cardForm.card.type != 0"
                            :src="
                              '/ffxiv/surface_icon/type' +
                              cardForm.card.type +
                              '.png'
                            "
                          ></v-img>
                        </v-avatar>
                      </template>

                      <v-badge
                        left
                        avatar
                        overlap
                        offset-x="25"
                        offset-y="25"
                        color="transparent"
                      >
                        <template v-slot:badge>
                          <v-avatar>
                            <v-img
                              :src="
                                '/ffxiv/surface_icon/rarity' +
                                cardForm.card.rarity +
                                '.png'
                              "
                            ></v-img>
                          </v-avatar>
                        </template>

                        <div>
                          <v-img
                            :title="cardForm.card.describe"
                            :src="cardForm.picture"
                          ></v-img>
                          <div
                            class="card-surface-edges-number-box surface-text"
                          >
                            <div>
                              {{ cardSurfaceNumberHandler(cardForm.card.top) }}
                            </div>
                            <div>
                              {{ cardSurfaceNumberHandler(cardForm.card.left) }}
                              &nbsp; &nbsp;
                              {{
                                cardSurfaceNumberHandler(cardForm.card.right)
                              }}
                            </div>
                            <div>
                              {{
                                cardSurfaceNumberHandler(cardForm.card.bottom)
                              }}
                            </div>
                          </div>
                        </div>
                      </v-badge>
                    </v-badge>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card max-width="250">
                    <v-file-input
                      v-model="selectCardPictureFiles"
                      accept="image/*"
                      @change="changeCardPictureFile()"
                      label="更换图片"
                      dense
                      class="ma-1 pa-2"
                    ></v-file-input>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card max-width="50" class="ma-3">
                    <v-img width="50" height="50" :src="cardForm.icon"></v-img>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card max-width="250">
                    <v-file-input
                      v-model="selectCardIconPictureFiles"
                      accept="image/*"
                      @change="changeCardIconPictureFile()"
                      label="更换图标"
                      dense
                      class="ma-1 pa-2"
                    ></v-file-input>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" sm="9" xs="12">
              <v-card>
                <template slot="progress">
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>
                <v-card-text>
                  <v-form ref="form" v-model="cardForm.valid" lazy-validation>
                    <v-row dense>
                      <v-col cols="2">
                        <v-text-field
                          v-model="cardForm.card.id"
                          label="ID"
                          :disabled="true"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          v-model="cardForm.card.name"
                          :counter="20"
                          label="卡牌名称"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3">
                        <v-select
                          v-model="cardForm.card.rarity"
                          :items="cardRarityItems"
                          label="稀有度"
                        ></v-select>
                      </v-col>

                      <v-col cols="3">
                        <v-select
                          v-model="cardForm.card.type"
                          :items="cardTypeItems"
                          item-text="name"
                          item-value="code"
                          label="卡片类型"
                          persistent-hint
                        ></v-select>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          v-model="cardForm.card.cardNo"
                          label="卡牌编号"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          v-model="cardForm.card.cardCode"
                          label="卡牌编码"
                          :disabled="true"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="cardForm.card.top"
                          label="上"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="cardForm.card.right"
                          label="右"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="cardForm.card.bottom"
                          label="下"
                        ></v-text-field
                      ></v-col>
                      <v-col cols="3">
                        <v-text-field
                          v-model="cardForm.card.left"
                          label="左"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          v-model="cardForm.card.describe"
                          label="卡牌描述"
                        ></v-textarea>
                      </v-col>

                      <v-col cols="12">
                        <v-btn
                          :disabled="!cardForm.valid"
                          color="success"
                          class="mr-4"
                          title="当ID为-1时增加数据，其他情况更新数据"
                          @click="validate"
                        >
                          更新
                        </v-btn>

                        <v-btn
                          class="mr-4"
                          @click="newCard()"
                          title="点击后将会清空表单数据，并且更改为增加数据的状态"
                        >
                          新增数据
                        </v-btn>

                        <v-btn
                          class="mr-4"
                          color="red"
                          @click="deleteCard()"
                          title="删除当前ID的数据"
                          >删除</v-btn
                        >

                        <v-btn
                          class="mr-4"
                          title="关闭"
                          @click="cardInfoDialog = false"
                          >关闭
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card dark>
      <v-card
        width="150"
        class="ma-1 pa-2 text-center float-left"
        v-for="item in cardList"
        :key="item.id"
        @click="selectCard(item)"
      >
        <v-badge avatar overlap offset-x="25" offset-y="25" color="transparent">
          <template v-slot:badge>
            <v-avatar>
              <v-img
                v-if="item.type != 0"
                :title="cardTypeHandler(item.type)"
                :src="'/ffxiv/surface_icon/type' + item.type + '.png'"
              ></v-img>
            </v-avatar>
          </template>

          <v-badge
            left
            avatar
            overlap
            offset-x="25"
            offset-y="25"
            color="transparent"
          >
            <template v-slot:badge>
              <v-avatar>
                <v-img
                  :title="item.rarity"
                  :src="'/ffxiv/surface_icon/rarity' + item.rarity + '.png'"
                ></v-img>
              </v-avatar>
            </template>

            <div>
              <v-img
                :title="item.describe"
                :src="'/resources/ffxiv/surface/' + item.cardCode + '.png'"
                lazy-src="/ffxiv/surface/null-surface.png"
              ></v-img>
              <div class="card-surface-edges-number-box surface-text">
                <div>{{ cardSurfaceNumberHandler(item.top) }}</div>
                <div>
                  {{ cardSurfaceNumberHandler(item.left) }} &nbsp; &nbsp;
                  {{ cardSurfaceNumberHandler(item.right) }}
                </div>
                <div>{{ cardSurfaceNumberHandler(item.bottom) }}</div>
              </div>
            </div>
          </v-badge>
        </v-badge>

        <v-card-text :title="item.name">{{
          cardNameHandler(item.name)
        }}</v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<style scoped>
.card-surface-edges-number-box {
  position: absolute;
  bottom: 0;
  z-index: 1;
  text-align: center;
  width: 100%;
}

.surface-text {
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, -1px 0 #000;
}
</style>


<script>
import CardEdit from "./cardEdit";
export default CardEdit;
</script>
